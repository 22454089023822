var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { "text-xs-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { "offset-sm3": "", sm6: "", xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c("v-responsive", {
                    style: {
                      background: `rgb(${_vm.red}, ${_vm.green}, ${_vm.blue})`,
                    },
                    attrs: { height: "300px" },
                  }),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "", "grid-list-lg": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs9: "" } },
                                [
                                  _c("v-slider", {
                                    attrs: { max: 255, label: "R" },
                                    on: { input: _vm.set_color },
                                    model: {
                                      value: _vm.red,
                                      callback: function ($$v) {
                                        _vm.red = $$v
                                      },
                                      expression: "red",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mt-0",
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.red,
                                      callback: function ($$v) {
                                        _vm.red = $$v
                                      },
                                      expression: "red",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs9: "" } },
                                [
                                  _c("v-slider", {
                                    attrs: { max: 255, label: "G" },
                                    on: { input: _vm.set_color },
                                    model: {
                                      value: _vm.green,
                                      callback: function ($$v) {
                                        _vm.green = $$v
                                      },
                                      expression: "green",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mt-0",
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.green,
                                      callback: function ($$v) {
                                        _vm.green = $$v
                                      },
                                      expression: "green",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs9: "" } },
                                [
                                  _c("v-slider", {
                                    attrs: { max: 255, label: "B" },
                                    on: { input: _vm.set_color },
                                    model: {
                                      value: _vm.blue,
                                      callback: function ($$v) {
                                        _vm.blue = $$v
                                      },
                                      expression: "blue",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mt-0",
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.blue,
                                      callback: function ($$v) {
                                        _vm.blue = $$v
                                      },
                                      expression: "blue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }